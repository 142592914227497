import './App.scss';
import { NavBar, Header, Footer, Services, Careers, Contacts } from './components'


function App() {
  return (
    <div className="App">
      <NavBar />
      <Header />
      <Services />
      <Careers />
      <Contacts />
      <Footer />      
    </div>
  );
}

export default App;
