import React, { useState } from 'react'
import { HiMenu, HiX } from 'react-icons/hi';
import { motion }from 'framer-motion'


import './NavBar.scss'

const Navbar = () => {

    const [toggle, setToggle] = useState(false)

  return (
    <nav className='navigation'>
        
        {/* optional logo before links */}
        <div className="nav-logo">
            Xand Maintenance LLC.
        </div>
       

        {/* List of links to website */}
        <ul className="nav_list">
            {['Home', 'Services', 'Careers', 'Contacts',].map((link) => (
                <li className="nav_links" key={link}>
                    <div />
                    <a href={`#${link}`}>{link}</a>
                </li>
            ))}
        </ul>

        <div className='mini-menu'>
            <HiMenu onClick={() => setToggle(true)} />

            {toggle &&  (
                <motion.div 
                whileInView={{ x: [150, 0] }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                    <HiX  onClick={() => setToggle(false)}/>            
                    <ul>
                        {['Home', 'Services', 'Careers', 'Contacts',].map((link) => (
                            <li key={link}>
                                <a href={`#${link}`} onClick={() => setToggle(false)}>{link}</a>
                            </li>
                        ))}
                    </ul>
                </motion.div>
            )}
        </div>

    </nav>
  )
}

export default Navbar