import React from 'react'
import { images } from '../../constants'
import { motion } from 'framer-motion'
import './Header.scss'

const Header = () => {
  return (
    <section id="hero">
      <div className="header">
        <motion.div 
        whileInView={{y: [100, 0], opacity: [0, 1]}}
        transition={{ duration: 1, ease: 'easeInOut' }}
        className="header-box">
          <div className="header-content">
            <img src={images.logo} alt="logo" />
          </div>

          <div className="header-content">
            <div className="hero_text">
              <h1>Family Owned and Operated</h1>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default Header