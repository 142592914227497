import React, { useState, useEffect } from 'react'
import { images } from '../../constants'
import { motion } from 'framer-motion'

import './Services.scss'

const Services = () => {

  const imageList = [images.floor, images.floor2, images.carpet, images.carpet2, images.grout]
  
  const [counter, setCounter] = useState(0)
  let count = 0;
  useEffect(() => {
    const interval = setInterval(() => {
       
      if(count >= imageList.length - 1) {
        count = 0
      } else {
        count++
      }
      setCounter(count)
    }, 4000);
    
    
    return () => clearInterval(interval);
  
  }, [])
  

  return (
    <section id="Services">
      <div className="services">
        <div className="services-box">
          <motion.div 
          whileInView={{x: [-100, 0], opacity: [0, 1]}}
          transition={{ duration: 1, ease: 'easeInOut' }}
          className="services-item"
          >
            <div className="service-content">
              
              <div className="service-text">
                <h1>Services</h1>              
                <p>Some of the many services Xand Maintenance LLC. offer are:</p>
                <ul>
                  <li>Commercial Janitorial Maintenance</li>
                  <li>Carpet Cleaning & Maintenance</li>
                  <li>Tile & Grout Cleaning and Restoration</li>
                  <li>Marble and Terrazzo Cleaning & Restoration</li>
                  <li>Floor Cleaning & Refinishing</li>
                  <li>Construction Clean Up</li>
                </ul>
                <p>Looking for a specific service not mentioned? </p>
                <p><a href="#Contact">Contact</a> us to find out more.</p>
              </div>
            </div>
          </motion.div>
          
          <motion.div 
          whileInView={{x: [100, 0], opacity: [0, 1]}}
          transition={{ duration: 1, ease: 'easeInOut' }}
          className="services-item"
          >
            <img src={imageList[counter]} alt='service picture'/>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Services