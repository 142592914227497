import logo from '../assets/logo.png'
import career from '../assets/career.jpg'
import carpet from '../assets/carpet.jpg'
import carpet2 from '../assets/carpet2.jpg'
import floor from '../assets/floor.jpg'
import floor2 from '../assets/floor2.jpg'
import grout from '../assets/grout.jpg'
import hero from '../assets/Hero.jpg'
import hero2 from '../assets/hero2.jpg'

export default {
    logo,
    career,
    carpet,
    carpet2,
    floor,
    floor2,
    grout,
    hero,
    hero2
};