import React from 'react'
import { motion } from 'framer-motion'
import './Contacts.scss'

const Contacts = () => {
  return (
    <section id="Contact">
        <div className="contact">
            <div className="contact-box">
                <motion.div 
                whileInView={{x: [-100, 0], opacity: [0, 1]}}
                transition={{ duration: 1, ease: 'easeInOut' }}
                className="contact-item">
                    <div className="contact-content">
                      <h1>Contact</h1>
                      <h4>Want to get in touch? Get a quote? Contact us by phone or Email!</h4>
                      <a href="tel: +1 (443) 472-6390">{`-> `}Phone: 443-472-6390</a> 
                      <a href="mailto:kilewisco@gmail.com"> {`-> `}Email: wil.w@xandmaint.com</a>
                    </div>
                </motion.div>
        
                <motion.div 
                whileInView={{x: [100, 0], opacity: [0, 1]}}
                transition={{ duration: 1, ease: 'easeInOut' }}
                className="contact-item">
                    <i className="fa fa-address-book extra"></i>
                </motion.div>
            </div>
        </div>
    </section>
  )
}

export default Contacts