import React, { useState } from 'react'
import { images } from '../../constants'
import emailjs from 'emailjs-com'
import { motion } from 'framer-motion'

import './Careers.scss'

const Careers = () => {

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
  }

  function sendEmail(e) {
    e.preventDefault()

    emailjs.sendForm('service_6sk1kcd', 'template_np93zhk', e.target, 'YKnEEQgQYCqA3iofa')
    .then(res => {
      setLoading(false);
      setIsFormSubmitted(true);
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <section id="Careers">
      <div className="careers">
        <div className="careers-box">
              <motion.div 
              whileInView={{x: [-100, 0], opacity: [0, 1]}}
              transition={{ duration: 1, ease: 'easeInOut' }}
              className="careers-item">
                <img src={images.career} alt="" />
              </motion.div>
      
              <motion.div 
              whileInView={{x: [100, 0], opacity: [0, 1]}}
              transition={{ duration: 1, ease: 'easeInOut' }}
              className="careers-item">
                  <div className="careers-content">
                    <div className="careers-text">
                      <h1>Careers</h1>
                      <h4>Help is always wanted here at Xand Maintenance LLC.</h4>
                      <p>Whether you're looking for full time, part time, or even contractual work, we have something for you! Fill out the form below if you are looking for work.</p>
                      
                      {!isFormSubmitted ?
                        <form onSubmit={sendEmail}>
                            <div>
                                <label htmlFor="fname" >First name:</label>
                                <input type="text" id="fname" name="fname" placeholder="John" required/>

                                <label htmlFor="lname" >Last name:</label>
                                <input type="text" id="lname" name="lname" placeholder="Smith" required/>
                                
                                <label htmlFor="email">Email:</label>
                                <input type="email" id="email" name="email" placeholder="JohnSmith@email.com" required/>

                                <label htmlFor="resume">Resume upload(optional):  </label>
                                <input type="file" id="resume" name='resume'/>
                                
                                <button type="submit" id="submit" onClick={handleSubmit} >{loading ? 'Sending': 'Send Message'}</button>
                            </div>
                        </form>:
                        <div className="submitted">
                          <h1>Thanks for your interest! We'll get back to you as soon as possible.</h1>
                        </div>}
                    </div>
                  </div>
              </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Careers