import React from 'react'

import './Footer.scss'

const Footer = () => {

  
  return (
    <>
      <div className="contacts">
        <div className="socials">
          <h3>Check out our socials</h3>
          <a href="https://www.facebook.com/Xand-Maintenance-LLC-100439644733539/" target='_blank'><i className="fa fa-facebook-official"></i></a>
        </div>
      </div>

      <div className="info">
        <div className="copyright">
          <p>&copy; Copyright 2022, Xand Maintenance LLC.</p>
        </div>
        <div className="shameless-plug">
          <a href='https://kwiscovitch.xyz/' target='_blank'>~~~</a>
        </div>
      </div>
    </>
  )
}

export default Footer